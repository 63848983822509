import products from '../data/products.json'
import { useShoppingCart, formatCurrencyString } from 'use-shopping-cart'
import useSWR from 'swr'
import { supabase } from '../utils/initSupabase'
import { useEffect, useState } from 'react'
import { useUser } from '../lib/UserContext'
import toast from 'react-hot-toast'

const fetcher = (url, token) =>
  fetch(url, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
  }).then((res) => res.json())


const Products = () => {
  const { checkoutSingleItem } = useShoppingCart()
  
  const { user, session } = useUser()
  const { data, error } = useSWR(session ? ['/api/getUser', session.access_token] : null, fetcher)
  const [authView, setAuthView] = useState('sign_in')

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'PASSWORD_RECOVERY') setAuthView('update_password')
      if (event === 'USER_UPDATED') setTimeout(() => setAuthView('sign_in'), 1000)
      // Send session to /api/auth route to set the auth cookie.
      // NOTE: this is only needed if you're doing SSR (getServerSideProps)!
      fetch('/api/auth', {
        method: 'POST',
        headers: new Headers({ 'Content-Type': 'application/json' }),
        credentials: 'same-origin',
        body: JSON.stringify({ event, session }),
      }).then((res) => res.json())
    })

    return () => {
      authListener.unsubscribe()
    }
  }, [])

  const registerUser = async event => {

    const res = await fetch(
      'https://mail.desica.uk/subscription/form',
      {
        body: JSON.stringify({
          email: event.target.email.value,
          l: event.target.l.value
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      }
    )

    const result = await res.json()
  }

  var show = true

  function toggle() {
    const btn = document.getElementById('btn')
    const form = document.getElementById('form')
    btn.classList.add('hidden')
    form.classList.remove('hidden')
  }

  return (
    <div className="mx-auto px-4 sm:px-6 lg:px-8 bg-primary">
    <div className="lg:text-center mb-6">
      <p className="text-base leading-6 text-gray-500 font-semibold tracking-wide uppercase opacity-50">
        Pricing
      </p>
      <h1 className="pt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-200 sm:text-4xl sm:leading-10">
        How much does it cost?
      </h1>
      <p className="mt-4 max-w-2xl text-xl leading-7 text-accents-3 lg:mx-auto">We&apos;ll build out your site, and then some.</p>
    </div>
    <section className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3 pb-10 px-2 sm:px-6 md:px-6 lg:px-24">
      {products.map((product) => (
        <div key={product.sku} className="rounded-lg shadow-sm divide-y divide-accents-2 bg-primary-2 p-8">
          <h2 className="text-2xl leading-6 font-semibold text-base mb-2">{product.name}</h2>
          <p className="font-semibold mb-4 text-accents-4 border-none">
            {formatCurrencyString({
              value: product.price,
              currency: product.currency,
            })}
          </p>
          <p className="flex-wrap border-none">{product.description}</p>
          <button
            className="mt-8 block w-full rounded-md py-2 text-sm font-semibold text-gray-900 text-center bg-white hover:bg-gray-200 focus:outline-none"
            onClick={() => {if (user) checkoutSingleItem({ sku: product.sku }); else toast.error("You&apos;ll need to login to purchase");}}
          >
            Purchase
          </button>
        </div>
      ))}
      <div className="rounded-lg shadow-sm divide-y divide-accents-2 bg-primary-2 p-8">
          <h2 className="text-2xl leading-6 font-semibold text-base mb-2">Enterprise</h2>
          <p className="font-semibold mb-4 text-accents-4 border-none">
            Contact sales
          </p>
          <p className="flex-wrap border-none">Reach out to our sales team for a bespoke application, made for you.</p>
          <button id="btn" onClick={toggle} className="mt-8 block w-full rounded-md py-2 text-sm font-semibold text-gray-900 text-center bg-white hover:bg-gray-200 focus:outline-none">
            Get in touch
          </button>
          <form id="form" className="hidden relative text-gray-700 mt-8 border-none" onSubmit={registerUser} action="/api/form" method="POST">
            <input name="email" id="email" className="w-full h-10 pl-4 text-base placeholder-gray-600 rounded-md focus:shadow-outline focus:outline-none pr-28 bg-primary" type="email" placeholder="Enter an email"/>
            <button className="m-1 absolute inset-y-0 right-0 flex items-center px-4 font-bold text-pink bg-pink-600 rounded-md hover:bg-pink-500 focus:bg-pink-700 focus:outline-none" type="submit" value="Sign up">Contact us</button>
            <input id="2a383" type="checkbox" name="l" value="2a383d18-1723-48df-96b2-587401327097" className="hidden" checked readOnly />
          </form>
        </div>
    </section>
    </div>
  )
}

export default Products
