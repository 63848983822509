export default function Faq () {
    return (
        <section className="pt-20 pb-24 mx-auto px-4 sm:px-6 lg:px-8 bg-black">
  <div className="lg:text-center mb-6">
      <p className="text-base leading-6 text-gray-500 font-semibold tracking-wide uppercase opacity-50">
        FAQ
      </p>
      <h1 className="pt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-200 sm:text-4xl sm:leading-10">
        I have questions
      </h1>
      <p className="mt-4 max-w-2xl text-xl leading-7 text-accents-3 lg:mx-auto">If you have a question, check below. If your question isn&apos;t answered, <a href="https://desica.atlassian.net/servicedesk/customer/portal/1" rel="noreferrer" className="text-accents-5 hover:text-accents-7">contact support</a>.</p>
    </div>
  <div className="flex items-start justify-start mb-12 mt-12">
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-none w-6 h-6 mr-4 text-gray-100" aria-hidden="true">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
    <div>
      <p className="mt-0 mb-3 font-semibold text-gray-100">Can I have the code afterwards?</p>
      <p className="text-gray-400">
        In short, yes you can. When we make you a website, you can do whatever you want with the code. That means that you can publish the code for the world to see, if you want. We&apos;ll allow you to see the code at any time in our development process.
      </p>
    </div>
  </div>
  <div className="flex items-start justify-start mb-12">
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-none w-6 h-6 mr-4 text-gray-100" aria-hidden="true">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
    <div>
      <p className="mt-0 mb-3 font-semibold text-gray-100">Do you follow CSS and JavaScript accessibility best practices?</p>
      <p className="text-gray-400">
        Yes we do. We&apos;ll make sure that your website follows code best practices, and accsesibility guidelines. We&apos;ll also ensure that your code scores highly in Google&apos;s web audit, which means it will also be ranked highly in search results.
      </p>
    </div>
  </div>
  <div className="flex items-start justify-start mb-12">
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="flex-none w-6 h-6 mr-4 text-gray-100" aria-hidden="true">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      ></path>
    </svg>
    <div>
      <p className="mt-0 mb-3 font-semibold text-gray-100">What counts as a redesign?</p>
      <p className="text-gray-400">
        We define a rediesign as a significant change to the website&apos;s layout, colors, or purpose. This means that if you wanted to edit some text or change the color of a button, that would count as one of your <b>unlimited alterations</b>. You are allowed three redesigns.
      </p>
    </div>
  </div>
</section>

    )
}