export default function Review () {
    return (
        <section className="bg-black">
  <div className="w-full px-4 py-20 mx-auto text-center max-w-7xl md:w-4/5 lg:w-4/6">
    <h1 className="mt-3 mb-10 text-xl font-extrabold text-white md:leading-snug md:text-3xl">
      &quot;Great quality! They make amazing websites with <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink to-purple-500">exact detail</span>, and they always ask for the infomation that they need to make your website. An excellent web developer.&quot;
    </h1>
    <p className="text-base font-medium text-gray-200">Harry De Meo</p>
    <p className="text-xs font-medium text-gray-400">Customer</p>
  </div>
</section>

    )
}