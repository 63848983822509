import { NextPage } from 'next'
import Link from 'next/link'
import Cta from '../components/cta'
import Faq from '../components/Faq'
import Features from '../components/features'
import Header from '../components/header'
import Layout from '../components/Layout'
import Products from '../components/Products'
import Review from '../components/Review'

const IndexPage: NextPage = () => {
  return (
    <Layout title="Desica - The simple web development company">
      <Header />
      <Features />
      <Review />
      <Products />
      <Faq />
      <Cta />
    </Layout>
  )
}

export default IndexPage
