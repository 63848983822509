import Link from "next/link";

export default function Cta() {
  const registerUser = async event => {

    const res = await fetch(
      'https://mail.desica.uk/subscription/form',
      {
        body: JSON.stringify({
          email: event.target.email.value,
          l: event.target.l.value
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      }
    )

    const result = await res.json()
    // result.user => 'Ada Lovelace'
  }

    return (
        <div id="bg" className="bg-accents-0">
  <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
    <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-white sm:text-4xl sm:leading-10">
      Ready to dive in?
      <br />
      <span className="text-pink">Your website awaits.</span>
    </h2>
    <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
      <form className="relative text-gray-700" onSubmit={registerUser} action="/api/form" method="POST">
          <input name="email" id="email" className="w-full h-16 pl-4 text-base placeholder-gray-600 rounded-lg focus:shadow-outline focus:outline-none pr-28 bg-primary" type="email" placeholder="Enter an email"/>
          <button className="m-1 absolute inset-y-0 right-0 flex items-center px-4 font-bold text-pink bg-pink-600 rounded-md hover:bg-pink-500 focus:bg-pink-700 focus:outline-none" type="submit" value="Sign up">Sign up</button>
          <input id="2a383" type="checkbox" name="l" value="2a383d18-1723-48df-96b2-587401327097" className="hidden" checked readOnly />
        </form>
    </div>
  </div>
</div>
    )
}