export default function Header() {
  const registerUser = async event => {
    
    const res = await fetch(
      'https://mail.desica.uk/subscription/form',
      {
        body: JSON.stringify({
          email: event.target.email.value,
          l: event.target.l.value
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST'
      }
    )

    const result = await res.json()
    // result.user => 'Ada Lovelace'
  }

    return (
        <header className="h-screen flex flex-col justify-content-center items-center bg-accents-0" id="bg">
            <div className="m-auto px-5">
            <h1 className="text-4xl font-extrabold text-white text-center sm:text-6xl">
            Desica
          </h1>
          <p className="text-xl text-accents-6 text-center sm:text-2xl max-w-2xl mt-5">
            The simple web development company
          </p>
          <br />
          <br />
          <form className="relative text-gray-700" onSubmit={registerUser} action="/api/form" method="POST">
          <input name="email" className="w-full h-16 pl-4 text-base placeholder-gray-600 rounded-lg focus:shadow-outline focus:outline-none pr-28 bg-primary" type="email" placeholder="Enter an email"/>
          <button className="m-1 absolute inset-y-0 right-0 flex items-center px-4 font-bold text-pink bg-pink-600 rounded-md hover:bg-pink-500 focus:bg-pink-700 focus:outline-none" type="submit" value="Sign up">Sign up</button>
          <input id="2a383" type="checkbox" name="l" value="2a383d18-1723-48df-96b2-587401327097" className="hidden" checked readOnly />
        </form>
        <div className="mt-4 flex flex-col justify-start mb-3 space-x-0 space-y-2 text-xs text-gray-200 md:flex-row md:justify-center md:space-x-8 md:space-y-0">
      <div className="flex items-center">
        <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1 text-green-600">
          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
        </svg>
        Friendly team
      </div>
      <div className="flex items-center">
        <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1 text-green-600">
          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
        </svg>
        Multiple revisions
      </div>
      <div className="flex items-center">
        <svg viewBox="0 0 20 20" fill="currentColor" className="w-4 h-4 mr-1 text-green-600">
          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
        </svg>
        Cancel anytime
      </div>
    </div>
            </div>
        </header>
    )
}